import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";
import RegularExpressionFilterHandlerModule from "./RegularExpressionFilterHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class RegularExpressionFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: RegularExpressionFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "spellcheck",
    color: "rgba(var(--a-danger), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/RegularExpressionFilterHandlerModuleView.vue')

  aliases = [ 'номер', 'фильтры', 'удалять', 'удалить', ]

  tariffTags(): Array<TagData> | null {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.hasAtLeastOneAdvanced) ? ADVANCED_TAG : null
  }
}
