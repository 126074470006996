import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import SummarizeMessageHandlerModule from "./SummarizeMessageHandlerModule";
import store from "@/store/store";

export default class SummarizeMessageHandlerModuleBuilder extends ModuleBuilder {
  model!: SummarizeMessageHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/SummarizeMessageHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'filter',
  }

  isVisible(): boolean {
    return store.getters.isChatSet && store.state.chatState.chat!.version >= 500
  }
}

