import { ModuleTypesEnum } from "../types/types";

import ChatTopCommandHandlerModuleBuilder from "../models/modules/ChatTopCommandHandlerModule/ChatTopCommandHandlerModuleBuilder";
import ReportCommandHandlerModuleBuilder
  from "../models/modules/ReportCommandHandlerModule/ReportCommandHandlerModuleBuilder";
import ReferralModuleBuilder from "../models/modules/ReferralModule/ReferralModuleBuilder";
import AnchorsHandlerModuleBuilder from "../models/modules/AnchorsHandlerModule/AnchorsHandlerModuleBuilder";
import ApiNotifyHandlerModuleBuilder
  from "../models/modules/ApiNotifyHandlerModule/ApiNotifyHandlerModuleBuilder";

import CommandLimitHandlerModuleBuilder
  from "../models/modules/CommandLimitHandlerModule/CommandLimitHandlerModuleBuilder";
import ModerateCommandHandlerModuleBuilder
  from "../models/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModuleBuilder";
import RulesCommandHandlerModuleBuilder
  from "../models/modules/RulesCommandHandlerModule/RulesCommandHandlerModuleBuilder";
import SendWakeupMessageModuleBuilder from "../models/modules/SendWakeupMessageModule/SendWakeupMessageModuleBuilder";
import SendAdminMessageModuleBuilder from "../models/modules/SendAdminMessageModule/SendAdminMessageModuleBuilder";
import EntertainmentCallHandlerModuleBuilder
  from "../models/modules/EntertainmentCallHandlerModule/EntertainmentCallHandlerModuleBuilder";
import InactiveKickHandlerModuleBuilder
  from "../models/modules/InactiveKickHandlerModule/InactiveKickHandlerModuleBuilder";
import FirstInactiveKickHandlerModuleBuilder
  from "../models/modules/FirstInactiveKickHandlerModule/FirstInactiveKickHandlerModuleBuilder";
import EntertainmentHandlerModuleBuilder
  from "../models/modules/EntertainmentHandlerModule/EntertainmentHandlerModuleBuilder";
import ChatCryptoCommandHandlerModuleBuilder
  from "../models/modules/ChatCryptoCommandHandlerModule/ChatCryptoCommandHandlerModuleBuilder";
import DailyReportModuleBuilder from "../models/modules/DailyReportModule/DailyReportModuleBuilder";
import AlbumLimitHandlerModuleBuilder from "../models/modules/AlbumLimitHandlerModule/AlbumLimitHandlerModuleBuilder";
import CloseChatHandlerModuleBuilder from "../models/modules/CloseChatHandlerModule/CloseChatHandlerModuleBuilder";
import AutocorrectionHandlerModuleBuilder
  from "../models/modules/AutocorrectionHandlerModule/AutocorrectionHandlerModuleBuilder";
import NewUsersAttackHandlerModuleBuilder
  from "../models/modules/NewUsersAttackHandlerModule/NewUsersAttackHandlerModuleBuilder";
import NsfwHandlerModuleBuilder from "../models/modules/NsfwHandlerModule/NsfwHandlerModuleBuilder";
import EditMediaHandlerModuleBuilder from "../models/modules/EditMediaHandlerModule/EditMediaHandlerModuleBuilder";
import FacecontrolHandlerModuleBuilder
  from "../models/modules/FacecontrolHandlerModule/FacecontrolHandlerModuleBuilder";
import MessageBindedChannelHandlerModuleBuilder
  from "../models/modules/MessageBindedChannelHandlerModule/MessageBindedChannelHandlerModuleBuilder";
import NewUsersLimitHandlerModuleBuilder
  from "../models/modules/NewUsersLimitHandlerModule/NewUsersLimitHandlerModuleBuilder";
import BotGuardHandlerModuleBuilder
  from "../models/modules/BotGuardHandlerModule/BotGuardHandlerModuleBuilder";
import EnterBindedChannelHandlerModuleBuilder
  from "../models/modules/EnterBindedChannelHandlerModule/EnterBindedChannelHandlerModuleBuilder";
import StopWordFilterHandlerModuleBuilder from "../models/modules/filters/StopWordFilterHandlerModule/StopWordFilterHandlerModuleBuilder";
import StoryFilterHandlerModuleBuilder
  from "../models/modules/filters/StoryFilterHandlerModule/StoryFilterHandlerModuleBuilder";
import VideoNoteFilterHandlerModuleBuilder
  from "../models/modules/filters/VideoNoteFilterHandlerModule/VideoNoteFilterHandlerModuleBuilder";
import SpoilerMessageFilterHandlerModuleBuilder
  from "../models/modules/filters/SpoilerMessageFilterHandlerModule/SpoilerMessageFilterHandlerModuleBuilder";
import ContactFilterHandlerModuleBuilder
  from "../models/modules/filters/ContactFilterHandlerModule/ContactFilterHandlerModuleBuilder";
import DiceFilterHandlerModuleBuilder
  from "../models/modules/filters/DiceFilterHandlerModule/DiceFilterHandlerModuleBuilder";
import AudioFilterHandlerModuleBuilder
  from "../models/modules/filters/AudioFilterHandlerModule/AudioFilterHandlerModuleBuilder";
import HieroglyphMessageFilterHandlerModuleBuilder
  from "../models/modules/filters/HieroglyphMessageFilterHandlerModule/HieroglyphMessageFilterHandlerModuleBuilder";
import RtlMessageFilterHandlerModuleBuilder
  from "../models/modules/filters/RtlMessageFilterHandlerModule/RtlMessageFilterHandlerModuleBuilder";
import VoiceFilterHandlerModuleBuilder from "../models/modules/filters/VoiceFilterHandlerModule/VoiceFilterHandlerModuleBuilder";
import AnimationFilterHandlerModuleBuilder
  from "../models/modules/filters/AnimationFilterHandlerModule/AnimationFilterHandlerModuleBuilder";
import VideoFilterHandlerModuleBuilder from "../models/modules/filters/VideoFilterHandlerModule/VideoFilterHandlerModuleBuilder";
import PhotoFilterHandlerModuleBuilder from "../models/modules/filters/PhotoFilterHandlerModule/PhotoFilterHandlerModuleBuilder";
import DocumentFilterHandlerModuleBuilder
  from "../models/modules/filters/DocumentFilterHandlerModule/DocumentFilterHandlerModuleBuilder";
import RegularExpressionFilterHandlerModuleBuilder
  from "../models/modules/filters/RegularExpressionFilterHandlerModule/RegularExpressionFilterHandlerModuleBuilder";
import PermittedWordFilterHandlerModuleBuilder
  from "../models/modules/filters/PermittedWordFilterHandlerModule/PermittedWordFilterHandlerModuleBuilder";
import CensureFilterHandlerModuleBuilder
  from "../models/modules/filters/CensureFilterHandlerModule/CensureFilterHandlerModuleBuilder";
import CapsMessageFilterHandlerModuleBuilder
  from "../models/modules/filters/CapsMessageFilterHandlerModule/CapsMessageFilterHandlerModuleBuilder";
import MessageLengthFilterHandlerModuleBuilder
  from "../models/modules/filters/MessageLengthFilterHandlerModule/MessageLengthFilterHandlerModuleBuilder";
import AnyMessageFilterHandlerModuleBuilder
  from "../models/modules/filters/AnyMessageFilterHandlerModule/AnyMessageFilterHandlerModuleBuilder";
import ChatReplyFilterHandlerModuleBuilder
  from "../models/modules/filters/ChatReplyFilterHandlerModule/ChatReplyFilterHandlerModuleBuilder";
import ChannelMessageFilterHandlerModuleBuilder
  from "../models/modules/filters/ChannelMessageFilterHandlerModule/ChannelMessageFilterHandlerModuleBuilder";
import ChannelReplyFilterHandlerModuleBuilder
  from "../models/modules/filters/ChannelReplyFilterHandlerModule/ChannelReplyFilterHandlerModuleBuilder";
import BotReplyFilterHandlerModuleBuilder
  from "../models/modules/filters/BotReplyFilterHandlerModule/BotReplyFilterHandlerModuleBuilder";
import StickerFilterHandlerModuleBuilder
  from "../models/modules/filters/StickerFilterHandlerModule/StickerFilterHandlerModuleBuilder";
import FloodFilterHandlerModuleBuilder
  from "../models/modules/filters/FloodFilterHandlerModule/FloodFilterHandlerModuleBuilder";
import BotCommandFilterHandlerModuleBuilder from "../models/modules/filters/BotCommandFilterHandlerModule/BotCommandFilterHandlerModuleBuilder";
import StickerPackFilterHandlerModuleBuilder
  from "../models/modules/filters/StickerPackFilterHandlerModule/StickerPackFilterHandlerModuleBuilder";
import EmailLinksFilterHandlerModuleBuilder
  from "../models/modules/filters/EmailLinksFilterHandlerModule/EmailLinksFilterHandlerModuleBuilder";
import EmojiFilterHandlerModuleBuilder
  from "../models/modules/filters/EmojiFilterHandlerModule/EmojiFilterHandlerModuleBuilder";
import ReferralFilterHandlerModuleBuilder
  from "../models/modules/filters/ReferralFilterHandlerModule/ReferralFilterHandlerModuleBuilder";
import WebLinksFilterHandlerModuleBuilder
  from "../models/modules/filters/WebLinksFilterHandlerModule/WebLinksFilterHandlerModuleBuilder";
import SelfLinksFilterHandlerModuleBuilder
  from "../models/modules/filters/SelfLinksFilterHandlerModule/SelfLinksFilterHandlerModuleBuilder";
import ReputationMessageHandlerModuleBuilder
  from "../models/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModuleBuilder";
import WarnCommandHandlerModuleBuilder
  from "../models/modules/WarnCommandHandlerModule/WarnCommandHandlerModuleBuilder";
import XpHandlerModuleBuilder from "../models/modules/XpHandlerModule/XpHandlerModuleBuilder";
import RanksHandlerModuleBuilder
  from "../models/modules/RanksHandlerModule/RanksHandlerModuleBuilder";
import LeaveUsersHandlerModuleBuilder
  from "../models/modules/Greeting/LeaveUsersHandlerModule/LeaveUsersHandlerModuleBuilder";
import SilentAdminInviteHandlerModuleBuilder
  from "../models/modules/Greeting/SilentAdminInviteHandlerModule/SilentAdminInviteHandlerModuleBuilder";
import SilentInviteHandlerModuleBuilder
  from "../models/modules/Greeting/SilentInviteHandlerModule/SilentInviteHandlerModuleBuilder";
import NewUserTriggersHandlerModuleBuilder
  from "../models/modules/Greeting/NewUserTriggersHandlerModule/NewUserTriggersHandlerModuleBuilder";
import NewUserDeprecatedTriggersHandlerModuleBuilder from "../models/modules/Greeting/NewUserDeprecatedTriggersHandlerModule/NewUserDeprecatedTriggersHandlerModuleBuilder";
import NewUsersHandlerModuleBuilder
  from "../models/modules/Greeting/NewUsersHandlerModule/NewUsersHandlerModuleBuilder";
import LocationFilterHandlerModuleBuilder
  from "../models/modules/filters/LocationFilterHandlerModule/LocationFilterHandlerModuleBuilder";
import FirstCommentHandlerModuleBuilder from "../models/modules/FirstCommentHandlerModule/FirstCommentHandlerModuleBuilder";
import RestrictCommentHandlerModuleBuilder from "../models/modules/RestrictCommentHandlerModule/RestrictCommentHandlerModuleBuilder";
import NetworksModerateCommandHandlerModuleBuilder
  from "../models/modules/NetworksModerateCommandHandlerModule/NetworksModerateCommandHandlerModuleBuilder";
import VotebanHandlerModuleBuilder from "../models/modules/VotebanHandlerModule/VotebanHandlerModuleBuilder";
import SummarizeMessageHandlerModuleBuilder from "../models/modules/SummarizeMessageHandlerModule/SummarizeMessageHandlerModuleBuilder";
import SummarizePostHandlerModuleBuilder from "../models/modules/SummarizePostHandlerModule/SummarizePostHandlerModuleBuilder";

export default class ModuleBuildersFactory {
  static getBuilder(type: ModuleTypesEnum) {
    switch (type) {
      case ModuleTypesEnum.ApiNotifyHandlerModule:
        return new ApiNotifyHandlerModuleBuilder()
      case ModuleTypesEnum.AnchorsHandlerModule:
        return new AnchorsHandlerModuleBuilder()
      case ModuleTypesEnum.ReferralModule:
        return new ReferralModuleBuilder()
      case ModuleTypesEnum.ChatTopCommandHandlerModule:
        return new ChatTopCommandHandlerModuleBuilder()
        //moderation
      case ModuleTypesEnum.ModerateCommandHandlerModule:
        return new ModerateCommandHandlerModuleBuilder()
      case ModuleTypesEnum.NetworksModerateCommandHandlerModule:
        return new NetworksModerateCommandHandlerModuleBuilder()
      case ModuleTypesEnum.ReportCommandHandlerModule:
        return new ReportCommandHandlerModuleBuilder()
      case ModuleTypesEnum.CommandLimitHandlerModule:
        return new CommandLimitHandlerModuleBuilder()
      case ModuleTypesEnum.RulesCommandHandlerModule:
        return new RulesCommandHandlerModuleBuilder()
      //activity
      case ModuleTypesEnum.SendWakeupMessageModule:
        return new SendWakeupMessageModuleBuilder()
      case ModuleTypesEnum.SendAdminMessageModule:
        return new SendAdminMessageModuleBuilder()
      case ModuleTypesEnum.EntertainmentHandlerModule:
        return new EntertainmentHandlerModuleBuilder()
      case ModuleTypesEnum.EntertainmentCallHandlerModule:
        return new EntertainmentCallHandlerModuleBuilder()
      case ModuleTypesEnum.ChatCryptoCommandHandlerModule:
        return new ChatCryptoCommandHandlerModuleBuilder()
      case ModuleTypesEnum.InactiveKickHandlerModule:
        return new InactiveKickHandlerModuleBuilder()
      case ModuleTypesEnum.FirstInactiveKickHandlerModule:
        return new FirstInactiveKickHandlerModuleBuilder()
      case ModuleTypesEnum.DailyReportModule:
        return new DailyReportModuleBuilder()
      /* moderation extra */
      case ModuleTypesEnum.AlbumLimitHandlerModule:
        return new AlbumLimitHandlerModuleBuilder()
      case ModuleTypesEnum.CloseChatHandlerModule:
        return new CloseChatHandlerModuleBuilder()
      case ModuleTypesEnum.AutocorrectionHandlerModule:
        return new AutocorrectionHandlerModuleBuilder()
      case ModuleTypesEnum.NewUsersAttackHandlerModule:
        return new NewUsersAttackHandlerModuleBuilder()
      case ModuleTypesEnum.NsfwHandlerModule:
        return new NsfwHandlerModuleBuilder()
      case ModuleTypesEnum.EditMediaHandlerModule:
        return new EditMediaHandlerModuleBuilder()
      case ModuleTypesEnum.FacecontrolHandlerModule:
        return new FacecontrolHandlerModuleBuilder()
      case ModuleTypesEnum.MessageBindedChannelHandlerModule:
        return new MessageBindedChannelHandlerModuleBuilder()
      case ModuleTypesEnum.NewUsersLimitHandlerModule:
        return new NewUsersLimitHandlerModuleBuilder()
      case ModuleTypesEnum.BotGuardHandlerModule:
        return new BotGuardHandlerModuleBuilder()
      case ModuleTypesEnum.EnterBindedChannelHandlerModule:
        return new EnterBindedChannelHandlerModuleBuilder()
      // filters
      case ModuleTypesEnum.StopWordFilterHandlerModule:
        return new StopWordFilterHandlerModuleBuilder()
      case ModuleTypesEnum.StoryFilterHandlerModule:
        return new StoryFilterHandlerModuleBuilder()
      case ModuleTypesEnum.VideoNoteFilterHandlerModule:
        return new VideoNoteFilterHandlerModuleBuilder()
      case ModuleTypesEnum.SpoilerMessageFilterHandlerModule:
        return new SpoilerMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ContactFilterHandlerModule:
        return new ContactFilterHandlerModuleBuilder()
      case ModuleTypesEnum.DiceFilterHandlerModule:
        return new DiceFilterHandlerModuleBuilder()
      case ModuleTypesEnum.AudioFilterHandlerModule:
        return new AudioFilterHandlerModuleBuilder()
      case ModuleTypesEnum.HieroglyphMessageFilterHandlerModule:
        return new HieroglyphMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.RtlMessageFilterHandlerModule:
        return new RtlMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.VoiceFilterHandlerModule:
        return new VoiceFilterHandlerModuleBuilder()
      case ModuleTypesEnum.AnimationFilterHandlerModule:
        return new AnimationFilterHandlerModuleBuilder()
      case ModuleTypesEnum.VideoFilterHandlerModule:
        return new VideoFilterHandlerModuleBuilder()
      case ModuleTypesEnum.PhotoFilterHandlerModule:
        return new PhotoFilterHandlerModuleBuilder()
      case ModuleTypesEnum.DocumentFilterHandlerModule:
        return new DocumentFilterHandlerModuleBuilder()
      case ModuleTypesEnum.RegularExpressionFilterHandlerModule:
        return new RegularExpressionFilterHandlerModuleBuilder()
      case ModuleTypesEnum.PermittedWordFilterHandlerModule:
        return new PermittedWordFilterHandlerModuleBuilder()
      case ModuleTypesEnum.CensureFilterHandlerModule:
        return new CensureFilterHandlerModuleBuilder()
      case ModuleTypesEnum.CapsMessageFilterHandlerModule:
        return new CapsMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.MessageLengthFilterHandlerModule:
        return new MessageLengthFilterHandlerModuleBuilder()
      case ModuleTypesEnum.AnyMessageFilterHandlerModule:
        return new AnyMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ChannelMessageFilterHandlerModule:
        return new ChannelMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ChatReplyFilterHandlerModule:
        return new ChatReplyFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ChannelReplyFilterHandlerModule:
        return new ChannelReplyFilterHandlerModuleBuilder()
      case ModuleTypesEnum.BotReplyFilterHandlerModule:
        return new BotReplyFilterHandlerModuleBuilder()
      case ModuleTypesEnum.StickerFilterHandlerModule:
        return new StickerFilterHandlerModuleBuilder()
      case ModuleTypesEnum.FloodFilterHandlerModule:
        return new FloodFilterHandlerModuleBuilder()
      case ModuleTypesEnum.BotCommandFilterHandlerModule:
        return new BotCommandFilterHandlerModuleBuilder()
      case ModuleTypesEnum.StickerPackFilterHandlerModule:
        return new StickerPackFilterHandlerModuleBuilder()
      case ModuleTypesEnum.EmailLinksFilterHandlerModule:
        return new EmailLinksFilterHandlerModuleBuilder()
      case ModuleTypesEnum.EmojiFilterHandlerModule:
        return new EmojiFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ReferralFilterHandlerModule:
        return new ReferralFilterHandlerModuleBuilder()
      case ModuleTypesEnum.WebLinksFilterHandlerModule:
        return new WebLinksFilterHandlerModuleBuilder()
      case ModuleTypesEnum.SelfLinksFilterHandlerModule:
        return new SelfLinksFilterHandlerModuleBuilder()
      case ModuleTypesEnum.LocationFilterHandlerModule:
        return new LocationFilterHandlerModuleBuilder()
      case ModuleTypesEnum.WarnCommandHandlerModule:
        return new WarnCommandHandlerModuleBuilder()
      // reputation
      case ModuleTypesEnum.ReputationMessageHandlerModule:
        return new ReputationMessageHandlerModuleBuilder()
      case ModuleTypesEnum.XpHandlerModule:
        return new XpHandlerModuleBuilder()
      case ModuleTypesEnum.RanksHandlerModule:
        return new RanksHandlerModuleBuilder()
      //greeting
      case ModuleTypesEnum.LeaveUsersHandlerModule:
        return new LeaveUsersHandlerModuleBuilder()
      case ModuleTypesEnum.SilentInviteHandlerModule:
        return new SilentInviteHandlerModuleBuilder()
      case ModuleTypesEnum.SilentAdminInviteHandlerModule:
        return new SilentAdminInviteHandlerModuleBuilder()
      case ModuleTypesEnum.NewUserTriggersHandlerModule:
        return new NewUserTriggersHandlerModuleBuilder()
      case ModuleTypesEnum.NewUserDeprecatedTriggersHandlerModule:
        return new NewUserDeprecatedTriggersHandlerModuleBuilder()
      case ModuleTypesEnum.NewUsersHandlerModule:
        return new NewUsersHandlerModuleBuilder()
      //
      case ModuleTypesEnum.FirstCommentHandlerModule:
        return new FirstCommentHandlerModuleBuilder()
      case ModuleTypesEnum.RestrictCommentHandlerModule:
        return new RestrictCommentHandlerModuleBuilder()
      case ModuleTypesEnum.VotebanHandlerModule:
        return new VotebanHandlerModuleBuilder()
      case ModuleTypesEnum.SummarizeMessageHandlerModule:
        return new SummarizeMessageHandlerModuleBuilder()
      case ModuleTypesEnum.SummarizePostHandlerModule:
        return new SummarizePostHandlerModuleBuilder()

    }
  }
}
