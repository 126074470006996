import { ModuleConditionTypeEnum, IModuleConfigCondition } from "@/includes/logic/Modules/types/types";
import { Exclude } from "class-transformer";
import { snakeCase } from "lodash";

export default abstract class ConfigCondition implements IModuleConfigCondition {
  abstract type: ModuleConditionTypeEnum

  @Exclude()
  get snakeCaseType() {
    return snakeCase(this.type)
  }

  @Exclude()
  get titleKey() {
    return `module_condition_${ this.snakeCaseType }_title`
  }

  @Exclude()
  get helpMessageKey() {
    return `module_condition_${ this.snakeCaseType }_help_message`
  }
}
